<template>
    <picture class="position-absolute">
        <source
            media="(max-width: 1023px)"
            :srcset="require(`@/assets/img/blobs/${name}-mobile.png`)"
        />
        <source
            media="(min-width: 1024px)"
            :srcset="require(`@/assets/img/blobs/${name}.png`)"
        />
        <img
            :src="require(`@/assets/img/blobs/${name}.png`)"
            alt="shape"
            class="bck-shape img-fluid"
        />
    </picture>
</template>

<script>
export default {
    props: {
        name: {
            type: String,
            required: true
        }
    }
};
</script>

<style lang="scss" scoped></style>
