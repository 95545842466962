<template>
    <section class="section-wrapper">
        <div
            class="container-1650 container-fluid"
            :class="
                imgPosition === 'left'
                    ? 'mx-auto mr-md-auto ml-md-0 pl-md-0'
                    : 'mx-auto ml-md-auto mr-md-0 pr-md-0 '
            "
            v-in-viewport.once
        >
            <b-row class="no-gutters align-items-md-center fade-item">
                <b-col
                    cols="12"
                    md="7"
                    class="text-center text-md-left text-column"
                    :class="imgPosition === 'left' ? 'order-2' : ''"
                >
                    <h3 v-if="subtitle" class="subtitle text mb-1 mb-lg-2">
                        {{ subtitle }}
                    </h3>
                    <h2>{{ title }}</h2>
                    <div v-html="text" class="text my-3 my-lg-4"></div>
                    <template v-if="button">
                        <Button :url="button.url" :bgColor="buttonColor">{{
                            button.label
                        }}</Button>
                    </template>
                </b-col>
                <b-col
                    md="5"
                    class="img-shape"
                    :class="imgPosition === 'left' ? '_left' : '_right'"
                >
                    <picture
                        class="d-lg-flex"
                        :class="
                            imgPosition === 'left'
                                ? 'justify-content-start'
                                : 'justify-content-end'
                        "
                    >
                        <source
                            media="(max-width: 1023px)"
                            :srcset="image.sizes.medium"
                        />
                        <source
                            media="(min-width: 1024px)"
                            :srcset="image.url"
                        />
                        <img
                            :src="image.url"
                            :alt="image.alt"
                            :width="image.width"
                            :height="image.height"
                            class="img-fluid"
                        />
                    </picture>
                </b-col>
            </b-row>
        </div>
    </section>
</template>

<script>
import Button from "../components/Button.vue";

export default {
    props: {
        title: {
            type: String,
            required: true
        },
        subtitle: {
            type: String,
            required: false
        },
        text: {
            type: String,
            required: true
        },
        image: {
            type: Object,
            required: true
        },
        imgPosition: {
            type: String,
            default: "right"
        },
        button: {
            type: Object
        },
        buttonColor: {
            type: String,
            default: "blue"
        }
    },
    components: {
        Button
    }
};
</script>

<style lang="scss" scoped>
.section-wrapper {
    position: relative;
}
.text-column {
    position: relative;
    z-index: 1;
}
.img-shape {
    position: absolute;
    top: -30%;
    max-height: 550px;
    display: flex;
    align-items: center;
    justify-content: center;

    picture {
        width: 200px;

        @media screen and (min-width: 768px) {
            width: 30vw;
        }
        @media screen and (min-width: 1921px) {
            width: 25vw;
        }
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
    }

    &._left {
        left: -10%;

        @media screen and (min-width: 768px) {
            left: 0;
        }

        picture {
            margin-right: auto;
        }
    }
    &._right {
        right: -10%;

        @media screen and (min-width: 768px) {
            right: 0;
        }

        picture {
            margin-left: auto;
        }
    }

    @media screen and (min-width: 768px) {
        position: relative;
        max-height: 500px;
    }
}
.container-fluid {
    @media screen and (min-width: 1920px) {
        padding-left: 0;
        width: calc(100% - calc(calc(100% - 1400px) / 2));
        max-width: none;
    }
}
.subtitle {
    @media screen and (min-width: 1024px) {
        font-weight: bold;
    }
}
.workflow .img-shape img {
    @media screen and (min-width: 768px) {
        transform: scale(1.5);
    }
}
</style>
