<template>
    <div>
        <div
            class="workflow page-wrapper position-relative overflow-y-hidden"
            v-if="dataLoaded"
        >
            <div
                class="d-flex flex-column justify-content-center position-relative"
            >
                <ShapePicture class="shape-01" name="workflow/Shape-01" />
                <div class="pt-6 pt-xl-7">
                    <div
                        class="container-longer container-fluid text-white mb-2 mb-lg-5 mb-xl-6"
                    >
                        <div class="row text-center">
                            <h1 class="col-12">
                                {{ object.acf.hero_title }}
                            </h1>
                            <div
                                class="col-12 text-bigger"
                                v-html="object.acf.hero_description"
                            ></div>
                        </div>
                    </div>
                    <TwoColumnsText
                        :title="object.acf.intro_title"
                        :text="object.acf.intro_description"
                        :hideTitleOnMobile="true"
                    >
                    </TwoColumnsText>
                </div>
            </div>

            <div class="mt-xl-7">
                <ImageLeftRight
                    v-for="(stage, id) in object.acf.stages"
                    :key="stage.step"
                    :subtitle="stage.step"
                    :title="stage.name"
                    :text="stage.description"
                    :image="stage.image"
                    :imgPosition="id % 2 === 0 ? 'right' : 'left'"
                    :class="
                        id === object.acf.stages.length - 1
                            ? 'mb-4'
                            : 'my-7 my-md-6'
                    "
                />
            </div>
            <div class="d-flex justify-content-center mb-8 position-relative">
                <Button
                    :url="object.acf.stages_button.url"
                    :bgColor="object.acf.stages_button.color"
                    >{{ object.acf.stages_button.label }}</Button
                >
            </div>

            <!-- bottom page shape -->
            <!-- <figure class="bottom-page-shape">
            <img
                src="@/assets/img/blobs/workflow-bottom-shape.png"
                alt="shape"
                class="bck-shape img-fluid"
            />
        </figure> -->
        </div>
        <Footer />
    </div>
</template>

<script>
import axios from "axios";
import seoMixin from "@/mixins/seoMixin";
import ImageLeftRight from "../components/ImageLeftRight.vue";
import TwoColumnsText from "../components/TwoColumnsText.vue";
import Button from "../components/Button.vue";
import ShapePicture from "../components/ShapePicture.vue";
import Footer from "../components/Footer.vue";

export default {
    name: "workflow",
    mixins: [seoMixin],
    data() {
        return {
            object: {},
            dataLoaded: false
        };
    },
    components: {
        ImageLeftRight,
        Button,
        TwoColumnsText,
        ShapePicture,
        Footer
    },
    created() {
        const pageApi = "wp-json/wp/v2/pages/659?_fields=id,slug,acf";
        const data = axios.get(pageApi).then(res => {
            this.object = res.data;
            this.setSeo(
                this.object.acf.seo_title,
                this.object.acf.seo_description,
                "https://www.wearealive.com/WAA_default.jpeg",
                "https://www.wearealive.com" + this.$route.fullPath
            );
            this.dataLoaded = true;
            document.dispatchEvent(new Event("custom-render-trigger"));
            setTimeout(() => {
                $("body").css("visibility", "unset");
                this.$store.dispatch("setLoading", false);
            }, loadingDelay);
        });
    }
};
</script>
