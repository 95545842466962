<template>
    <b-container
        class="container-longer text-center text-lg-left two-columns-text"
        fluid
        v-in-viewport.once
    >
        <b-row class="fade-item">
            <b-col
                cols="12"
                lg="5"
                class="text-lg-right"
                :class="hideTitleOnMobile ? 'd-none d-lg-block' : ''"
            >
                <h2>
                    {{ title }}
                </h2>
            </b-col>
            <b-col cols="12" lg="7" class="pl-lg-5 my-2">
                <!-- richtext -->
                <div class="text" v-html="text"></div>
                <!-- optional button slot -->
                <slot name="button"></slot>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: "Here goes title",
            required: true
        },
        text: {
            type: String,
            default: "Here goes text",
            required: true
        },
        hideTitleOnMobile: {
            type: Boolean,
            required: false,
            defeault: false
        }
    }
};
</script>

<style lang="scss" scoped></style>
